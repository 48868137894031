import React from 'react'

import { Helmet } from 'react-helmet'

import get from 'axios'

import './home.css'

const Home = (props) => {
  return (
    <div id="Copy" className="home-container">
      <Helmet>
        <title>www.zigzatuzoo.xyz</title>
        <meta property="og:title" content="www.zigzatuzoo.xyz" />
      </Helmet>
      <button type="button" id="Submit" className="home-button button" onClick={submit}>
        <span>
          <span>Submit</span>
          <br></br>
        </span>
      </button>
      <input
        type="text"
        placeholder="Username"
        name="User"
        id="User"
        className="home-textinput input"
      />
      <input
        type="text"
        placeholder="Ban Reason"
        id="Reason"
        className="home-textinput1 input"
      />
      <textarea
        placeholder="Copy"
        disabled
        className="home-textarea textarea"
      ></textarea>
    </div>
  )
}

function submit(res){
  const config = {
    headers:{
      'Access-Control-Allow-Origin': 'https://rr-tools.zigzatuzoo.xyz'
    }
  };
  var user = document.getElementById("User").value;
  var request = document.getElementById("Reason").value;

  var data = null

  var url = 'https://apim.rec.net/accounts/account?username='+user

  get(url,config)
  .then(response => {
    
    data = response.data;
    console.log(data);
  })
  .catch(error => {
    console.log(error);
  });
  

  var submit = 'Display name: '+null+'\nUsername: '+user+'\nAccount ID: '+null+'\nAccount Created: '+null+'\nBan Request Reason: '+request;


}

export default Home